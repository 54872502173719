import type { AxiosInstance, AxiosResponse } from "axios";
import type {
  AdminAATItemsAssetItem,
  PatchTreeToAdopt,
  TreeToAdoptItem,
} from "../shared-types/api/api-aat-types";

const getRequestForDeletion = async (
  unAuthedAxios: AxiosInstance,
  email: string
): Promise<null | AxiosResponse<{
  ok: 1;
}>> => {
  try {
    const result = await unAuthedAxios.get(
      `auth/account-deletion-request?email=${encodeURI(email)}`
    );

    return result;
  } catch (err) {
    console.log("error on getRequestForDeletion");
    console.error(err);
    return null;
  }
};

const getTSPDataRequest = async (
  parameterizedEndpoint = "dashboard/fetch-tsp-data",
  authedAxios: AxiosInstance,
  filterText = "",
  limitOffset?: {
    limit: number;
    offset: number;
  }
): Promise<null | AxiosResponse<{
  ok: 1;
}>> => {
  const { limit, offset = null } = limitOffset ?? {};

  const hasOffset = offset || offset === 0;
  if (hasOffset && !limit) {
    throw new Error("invalid params: limit and offset");
  }

  const limitOffsetQueryString = hasOffset
    ? `?offset=${offset}&limit=${limit}`
    : `?offset=0`;
  const filterTextQueryString = filterText
    ? `&filterPlantingSiteText=${filterText}`
    : "";
  const queryString = `${limitOffsetQueryString}${filterTextQueryString}`;

  console.log("now queryinnmm", `${parameterizedEndpoint}${queryString}`);
  try {
    const result = await authedAxios.get(
      `${parameterizedEndpoint}${queryString}`
    );

    return result?.data;
  } catch (err) {
    console.log("error on getTSPData");
    console.error(err);
    return null;
  }
};

const getTSPData = async (
  authedAxios: AxiosInstance,
  filterText = "",
  limitOffset: {
    limit: number;
    offset: number;
  }
): Promise<null | AxiosResponse<{
  ok: 1;
}>> => {
  return getTSPDataRequest(
    "dashboard/fetch-tsp-data",
    authedAxios,
    filterText,
    limitOffset
  );
};

const getTSPAzureCSV = async (
  authedAxios: AxiosInstance,
  filterText = "",
  limitOffset?: {
    limit: number;
    offset: number;
  }
): Promise<null | AxiosResponse<{
  ok: 1;
}>> => {
  return getTSPDataRequest(
    "dashboard/fetch-reports-csv-1",
    authedAxios,
    filterText,
    limitOffset
  );
};

const getAATItem = async (
  authedAxios: AxiosInstance,
  id: string
): Promise<{
  isAxiosError?: boolean;
  data: AdminAATItemsAssetItem[];
}> => {
  try {
    const result = await authedAxios.get<{ data: AdminAATItemsAssetItem[] }>(
      "admin-aat/get-aat-item/" + id
    );

    console.log(
      JSON.stringify(
        {
          getAATItemgetAATItem: result.data,
        },
        null,
        2
      )
    );

    if (!result.data?.data) {
      throw new Error("something went wrong");
    }

    return {
      isAxiosError: false,
      data: result.data.data,
    };
  } catch (e) {
    console.error(e);
  }

  return {
    isAxiosError: true,
    data: {} as any,
  };
};

const getAllAATItems = async (
  authedAxios: AxiosInstance
): Promise<{
  isAxiosError?: boolean;
  data: TreeToAdoptItem[];
}> => {
  try {
    const result = await authedAxios.get<{ data: TreeToAdoptItem[] }>(
      "admin-aat/get-all-aat-items"
    );

    if (!result.data?.data) {
      throw new Error("something went wrong");
    }

    return {
      isAxiosError: false,
      data: result.data.data,
    };
  } catch (e) {
    console.error(e);
  }

  return {
    isAxiosError: true,
    data: {} as any,
  };
};

const postAATItem = async (
  authedAxios: AxiosInstance,
  body: {
    title: string;
  }
): Promise<{
  ok: number;
  data: {
    tree_to_adopt_id?: number;
  };
}> => {
  try {
    const resultPost = await authedAxios.post<{
      data: { tree_to_adopt_id?: number };
    }>("admin-aat/post-aat-item/", body);

    return {
      ok: 1,
      data: resultPost?.data.data,
    };
  } catch (e: any) {
    const error422 = e?.response?.data;
    if (error422.hasDuplicate) {
      alert("Error\nTitle already exists");
      return;
    }

    return {
      ok: 0,
      data: {},
    };
  }
};

const patchAATUploadStoryAsset = async (
  authedAxios: AxiosInstance,
  id: string,
  file: File,
  width: number,
  height: number,
  buffer: Buffer
): Promise<null | AxiosResponse<{
  ok: 1;
  data: {
    blob_url: string;
  };
}>> => {
  const formData = new FormData();
  const fileToUpload = new File([buffer], file.name, {
    type: file.type,
  });

  formData.append("files", fileToUpload);
  formData.append("width", width + "");
  formData.append("height", height + "");

  return authedAxios.patch(
    "admin-aat/patch-upload-aatstory-asset/" + id,
    formData,
    {
      headers: {
        Accept: "application/json",
        // "Content-Type": file.type,
      },
    }
  );
};

const patchAATUploadBanner = async (
  authedAxios: AxiosInstance,
  id: string,
  file: File,
  width: number,
  height: number,
  buffer: Buffer
): Promise<null | AxiosResponse<{
  ok: 1;
  data: {
    blob_url: string;
  };
}>> => {
  const formData = new FormData();
  const fileToUpload = new File([buffer], file.name, {
    type: file.type,
  });

  formData.append("files", fileToUpload);
  formData.append("width", width + "");
  formData.append("height", height + "");

  return authedAxios.patch(
    "admin-aat/patch-upload-aatstory-banner/" + id,
    formData,
    {
      headers: {
        Accept: "application/json",
        // "Content-Type": file.type,
      },
    }
  );
};

const patchAATItem = async (
  authedAxios: AxiosInstance,
  id: string,
  body: PatchTreeToAdopt
): Promise<null | AxiosResponse<{
  ok: 1;
  data: {
    blob_url: string;
  };
}>> => {
  return authedAxios.patch("admin-aat/patch-aat-item/" + id, body);
};

export {
  getRequestForDeletion,
  getTSPData,
  getTSPAzureCSV,
  getAATItem,
  postAATItem,
  patchAATUploadStoryAsset,
  patchAATUploadBanner,
  patchAATItem,
  getAllAATItems,
};
